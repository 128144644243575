<script setup lang="ts">
import { ref, onMounted } from "vue";

const carousel = ref(null);
onMounted(() => {
  carousel.value.scrollTo({
    left: (carousel.scrollWidth - carousel.clientWidth) / 2,
  });
});
</script>
<template>
  <div class="image-carousel" ref="carousel">
    <slot></slot>
  </div>
</template>
