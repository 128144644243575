<!-- todo: this file is basically the same as AddCookBtn => make DRY eventually -->
<template>
  <div class="icon-plus icon-btn-inline clickable" v-on:click.self="show">
    <modal v-if="state.showModal" v-on:close-request="hide">
      <template v-slot:header>
        <h3>Eters aanmelden</h3>
      </template>
      <template v-slot:body>
        <table>
          <tbody>
            <tr>
              <td>Zwerver aanmelden</td>
              <td>
                <select
                  v-model="state.selectedZwerver"
                  :disabled="state.submitting"
                >
                  <option disabled value="">Kies een zwerver</option>
                  <option
                    v-for="zwerver in nonDiners()"
                    :value="zwerver.id"
                    :key="zwerver.id"
                  >
                    {{ zwerver.firstname + " " + zwerver.surname }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-slot:footer>
        <button
          @click="store"
          class="button-modal glow"
          :disabled="state.submitting || state.selectedZwerver === null"
        >
          Aanmelden
        </button>
      </template>
    </modal>
  </div>
</template>

<script setup lang="ts">
import { reactive, PropType } from "vue";

import { postData } from "../../fetchUtils";
import { Diner } from "../../models/meal";
import { Zwerver } from "../../models/zwerver";

const props = defineProps({
  mealId: { type: Number, required: true },
  eaters: { type: Object as PropType<Diner[]>, required: true },
  zwervers: { type: Object as PropType<Zwerver[]>, required: true },
});

const emit = defineEmits(["newEaterAdded"]);

const state = reactive({
  showModal: false,
  submitting: false,
  selectedZwerver: null,
});

function show() {
  if (state.showModal) {
    return;
  }
  state.submitting = false;
  state.selectedZwerver = null;
  state.showModal = true;
}

function hide() {
  if (!state.showModal && !state.submitting) {
    return;
  }
  state.showModal = false;
}

function store() {
  state.submitting = true;
  postData("/api/diner/sign_up_other/", {
    meal_id: props.mealId,
    zwerver_id: state.selectedZwerver,
    web: true,
  })
    .then((response) => {
      state.submitting = false;
      state.selectedZwerver = null;
      hide();
      emit("newEaterAdded", response);
    })
    .catch((err) => {
      alert(`Het lukt niet om deze zwerver mee te laten eten: ${err}`);
      state.submitting = false;
    });
}

function nonDiners() {
  let e = props.eaters.map((c) => c.zwerver);
  return props.zwervers.filter((z) => !e.includes(z.id));
}
</script>
