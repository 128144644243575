import { createApp } from "vue";

import "./site.scss";

import { Fancybox, Carousel, Panzoom } from "@fancyapps/ui";
import RandomAlignVertLayout from "./components/wrappers/RandomAlignVertLayout.vue";
import Expander from "./components/wrappers/Expander.vue";
import ImageCarousel from "./components/wrappers/ImageCarousel.vue";

import Bonfire from "./components/Bonfire.vue";
import OweeBanner from "./components/OweeBanner.vue";
import Meals from "./components/Meals.vue";
import MealCard from "./components/meal/MealCard.vue";
import AddDinerBtn from "./components/meal/AddDinerBtn.vue";
import Modal from "./components/Modal.vue";
import AddCookBtn from "./components/meal/AddCookBtn.vue";
import AddGuestBtn from "./components/meal/AddGuestBtn.vue";
import GuestLabel from "./components/meal/Guest.vue";
import GuestModal from "./components/meal/GuestModal.vue";

const app = createApp({});

// Wrapping components
app.component("randomalignvert", RandomAlignVertLayout);
app.component("expander", Expander);
app.component("image-carousel", ImageCarousel);

// Stand-alone components
app.component("bonfire", Bonfire);
app.component("owee-banner", OweeBanner);
app.component("meals", Meals);
app.component("meal", MealCard);
app.component("guest", GuestLabel);
app.component("guest-modal", GuestModal);

// Modals (stand-alone components)
app.component("modal", Modal);
app.component("add-diner", AddDinerBtn);
app.component("add-cook", AddCookBtn);
app.component("add-guest", AddGuestBtn);

app.mount("#dz-app");

Fancybox.bind("[data-fancybox]", {});
