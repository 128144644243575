<script setup lang="ts">
import { reactive } from "vue";

const props = defineProps([
  "initialtext",
  "initialauthor",
  "authenticated",
  "assets",
]);

const state = reactive({
  text: props.initialtext,
  author: props.initialauthor,
});

async function updateBonfire(e: MouseEvent) {
  e.preventDefault();
  e.stopPropagation();
  let text = prompt("Het kampvuur is...");
  if (text == "" || text == null) {
    return;
  }
  setBonfire(text).catch((error) => alert(error));
}

async function setBonfire(text: string) {
  const response = await fetch("/api/bonfire/add/", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    body: JSON.stringify({ text: text }),
  });
  let result = await response.json();
  if (result.errors) {
    throw result.errors[0];
  }
  state.author = result.bonfire.author.firstname;
  state.text = result.bonfire.text;
}
</script>
<template>
  <div>
    <div class="bonfire-container">
      <div class="animation">
        <img
          class="bonfire-cogwheel"
          :src="props.assets + '/sprites/bonfire_cogwheel.gif'"
          alt="Bonfire cogwheel animation"
        />
        <img
          class="bonfire-logs"
          :src="props.assets + '/sprites/bonfire_logs.gif'"
          alt="Bonfire logs animation"
        />
        <img
          class="bonfire-fire"
          :src="props.assets + '/sprites/bonfire_fire.gif'"
          alt="Bonfire fire animation"
        />
        <img
          class="bonfire-fire-glow-1"
          :src="props.assets + '/sprites/bonfire_fire.gif'"
          alt="Bonfire fire animation"
        />
        <img
          class="bonfire-fire-glow-2"
          :src="props.assets + '/sprites/bonfire_fire.gif'"
          alt="Bonfire fire animation"
        />
      </div>
    </div>
    <p :class="{ 'bonfire-state': true, authenticated: (props.authenticated === 'True') }">
      <img
        v-if="(props.authenticated === 'True')"
        class="bonfire-state-edit-btn icon"
        @click="updateBonfire"
        :src="props.assets + '/icons/announcement.svg'"
        alt="Announcement icon"
      />
      <span class="bonfire-state-content-wrapper">
        <span class="bonfire-state-text-wrapper">
          <span class="bonfire-state-text-short-wrapper"
            ><span class="bonfire-state-text-short">{{
              state.text
            }}</span></span
          >
          <span class="bonfire-state-text-full-wrapper"
            ><span class="bonfire-state-text-full">{{ state.text }}</span></span
          >
          <span class="bonfire-state-text-prefix">Het kampvuur is...</span>
        </span>
        <span class="bonfire-state-author">{{ state.author }}</span>
      </span>
    </p>
  </div>
</template>
