<template>
  <div class="vert-layout">
    <expander>
      <div class="card">
        <div class="quick-buttons">
          <a
            v-if="hasPerm('delete_meal')"
            class="delete-button"
            @click="deleteMeal"
          ></a>
          <a
            v-if="hasPerm('change_meal')"
            class="edit-button"
            @click="editMeal"
          ></a>
        </div>

        <img
          v-if="mealMoment().getDay() === 2"
          class="icon"
          src="../../assets/wwwroot/icons/day_tues.svg"
          alt="Tue"
        />
        <img
          v-else-if="mealMoment().getDay() === 4"
          class="icon"
          src="../../assets/wwwroot/icons/day_thurs.svg"
          alt="Thu"
        />
        <img
          v-else
          class="icon"
          src="../../assets/wwwroot/icons/day_misc.svg"
          alt="Day"
        />

        <h2>{{ capitalize(getMealMomentStr()) }}</h2>
        <p class="meal-deadline" v-if="deadlineShouldShow(meal)">
          Aanmelden t/m {{ getMealDeadlineStr(meal) }}
        </p>
        <p class="meal-description">{{ meal.description }}</p>

        <p>
          Er zijn {{ meal.eaters.length + meal.guests.length }} aanmeldingen
          <span v-if="meal.guests.length > 0"
            >(waarvan {{ meal.guests.length }} gasten)</span
          >
        </p>

        <div class="expandable spaced">
          <h4>
            Zwervers
            <add-diner
              v-if="hasPerm('change_meal')"
              :mealId="meal.id"
              :eaters="meal.eaters"
              :zwervers="props.zwervers"
              @newEaterAdded="(n) => props.meal.eaters.push(n)"
            />
          </h4>
          <ul class="columnlist">
            <li v-for="eater in getSortedEaters(meal)" :key="eater.zwerver">
              {{ zwerverIdToName(eater.zwerver) }}
              <a
                v-if="hasPerm('change_meal')"
                @click="() => deleteDiner(eater)"
                class="icon-delete icon-btn-inline"
              ></a>
            </li>
          </ul>

          <h4>
            Gasten
            <add-guest :mealId="meal.id" @guestAdded="guestAdded" />
          </h4>
          <ul class="commalist">
            <guest
              v-for="guest in getSortedGuests(meal)"
              :guest="guest"
              :buddy="zwerverIdToName(guest.buddy)"
              :allowEdit="guest.buddy === user.id || hasPerm('change_guest')"
              @guestChanged="guestChanged"
              @guestDeleted="guestDeleted"
              :key="guest.id"
            />
          </ul>

          <h4>Diëten</h4>
          <ul>
            <li
              v-for="(names, diet) in getDiets()"
              :title="names.join(', ')"
              :key="diet"
            >
              {{ names.length }}x {{ diet }}
            </li>
          </ul>
          <h4>
            Koks
            <add-cook
              v-if="hasPerm('change_meal')"
              :mealId="props.meal.id"
              :cooks="meal.cooks"
              :zwervers="zwervers"
              @newCookAdded="(n) => props.meal.cooks.push(n)"
            />
          </h4>
          <ul class="commalist">
            <li v-for="cook in meal.cooks" :key="cook.zwerver">
              {{ zwerverIdToName(cook.zwerver)}}<a
                v-if="hasPerm('change_meal')"
                class="icon-delete icon-btn-inline"
                @click="() => deleteCook(cook)"
              ></a>
              <span v-else class="commalist-item-decorator">,&nbsp;</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="vert-layout align-stretch">
        <button
          v-if="meal.signup_open"
          class="checkbox glow"
          :class="{ checked: getUserIsEating() }"
          @click="toggleEating"
        >
          {{ getUserIsEating() ? "Ik eet mee" : "Ik wil mee-eten" }}
        </button>
        <button
          v-if="meal.signup_cooks_open"
          class="checkbox glow expandable"
          :class="{ checked: getUserIsCooking() }"
          @click="toggleCooking"
        >
          {{ getUserIsCooking() ? "Ik kook" : "Ik wil koken" }}
        </button>
      </div>
    </expander>
  </div>
</template>

<script setup lang="ts">
import { reactive, PropType } from "vue";

import type { Meal, Diner, Cook, Guest } from "../../models/meal";
import { postData } from "../../fetchUtils";
import type { User } from "../../models/user";
import type { Zwerver } from "../../models/zwerver";

const props = defineProps({
  meal: { type: Object as PropType<Meal>, required: true },
  zwervers: { type: Object as PropType<Zwerver[]>, required: true },
  user: { type: Object as PropType<User>, required: true },
});

const state = reactive({
  showEditor: false,
  submitting: false,
});

function capitalize(str: String): String {
  return String(str).charAt(0).toUpperCase() + str.slice(1);
}

function mealMoment(): Date {
  return new Date(Date.parse(`${props.meal.date}T${props.meal.time}`));
}

function closingMoment(): Date {
  let closing_date = props.meal.closing_date === null ? props.meal.date : props.meal.closing_date;
  let closing_time = props.meal.closing_time === null ? props.meal.time : props.meal.closing_time;

  return new Date(Date.parse(`${closing_date}T${props.meal.closing_time}`));
}

function deleteMeal() {
  window.location.href = "/meals/delete/" + props.meal.id;
}

function editMeal() {
  window.location.href = "/meals/edit/" + props.meal.id;
}

function getUserIsEating() {
  return props.meal.eaters.find((e) => e.zwerver == props.user.id) != undefined;
}

function setUserIsEating(yes: boolean) {
  if (yes) {
    props.meal.eaters = [...props.meal.eaters, { zwerver: props.user.id }];
  } else {
    props.meal.eaters = props.meal.eaters.filter(
      (c) => c.zwerver !== props.user.id
    );
  }
}

function getUserIsCooking() {
  return props.meal.cooks.find((e) => e.zwerver == props.user.id) != undefined;
}

function setUserIsCooking(yes: boolean) {
  if (yes) {
    props.meal.cooks = [...props.meal.cooks, { zwerver: props.user.id }];
  } else {
    props.meal.cooks = props.meal.cooks.filter(
      (c) => c.zwerver !== props.user.id
    );
  }
}

function toggleEating() {
  if (getUserIsEating()) {
    postData("/api/diner/sign_off/", { meal_id: props.meal.id, web: true })
      .then(() => setUserIsEating(false))
      .catch((err) => alert(`Het lukte niet je af te melden: ${err}`));
  } else {
    postData("/api/diner/sign_up/", { meal_id: props.meal.id, web: true })
      .then(() => setUserIsEating(true))
      .catch((err) => alert(`Het lukte niet je aan te melden: ${err}`));
  }
}

function toggleCooking() {
  if (getUserIsCooking()) {
    postData("/api/cook/sign_off/", { meal_id: props.meal.id, web: true })
      .then(() => setUserIsCooking(false))
      .catch((err) => alert(`Het lukte niet je af te melden: ${err}`));
  } else {
    postData("/api/cook/sign_up/", { meal_id: props.meal.id, web: true })
      .then(() => setUserIsCooking(true))
      .catch((err) => alert(`Het lukte niet je aan te melden: ${err}`));
  }
}

function deleteDiner(diner: Diner) {
  if (!confirm(`Eet ${zwerverIdToName(diner.zwerver)} toch niet mee?`)) {
    return;
  }
  postData("/api/diner/sign_off_other/", {
    meal_id: props.meal.id,
    zwerver_id: diner.zwerver,
  })
    .then(() => {
      props.meal.eaters = props.meal.eaters.filter(
        (c) => c.zwerver !== diner.zwerver
      );
    })
    .catch((err) => {
      alert(`Het lukte niet om een deze zwerver uit te schrijven: ${err}`);
      location.reload();
    });
}

function guestChanged(guest: Guest) {
  props.meal.guests = props.meal.guests.map((g) =>
    g.id == guest.id ? guest : g
  );
}

function guestAdded(guest: Guest) {
  props.meal.guests.push(guest);
}

function guestDeleted(guest: Guest) {
  props.meal.guests = props.meal.guests.filter((g) => g.id != guest.id);
}

function deleteCook(cook: Cook) {
  if (!confirm(`Gaat ${zwerverIdToName(cook.zwerver)} toch niet koken?`)) {
    return;
  }
  postData("/api/cook/sign_off_other/", {
    meal_id: props.meal.id,
    zwerver_id: cook.zwerver,
  })
    .then(() => {
      props.meal.cooks = props.meal.cooks.filter(
        (c) => c.zwerver !== cook.zwerver
      );
    })
    .catch((err) => {
      alert(`Het lukte niet om een kok uit te schrijven: ${err}`);
      location.reload();
    });
}

function hasPerm(permission: string): boolean {
  return props.user.permissions.find((a) => a == permission) !== undefined;
}

function getDiets(): { [description: string]: string[] } {
  let out = {} as Record<string, string[]>;
  props.meal.eaters.forEach((eater) => {
    let z = findZwerverById(eater.zwerver);
    if (z.diets === null || z.diets === "") {
      return;
    }
    let name = z.firstname + " " + z.surname;
    if (z.diets in out) {
      out[z.diets].push(name);
    } else {
      out[z.diets] = [name];
    }
  });
  props.meal.guests.forEach((guest) => {
    if (guest.diets === null || guest.diets === "") {
      return;
    }
    if (guest.diets in out) {
      out[guest.diets].push(guest.name);
    } else {
      out[guest.diets] = [guest.name];
    }
  });
  return out;
}

function findZwerverById(id: number): Zwerver {
  return props.zwervers.filter((z) => z.id == id)[0];
}

function zwerverIdToName(id: number): string {
  let zwerver = findZwerverById(id);
  return zwerver.firstname + " " + zwerver.surname;
}

function getMealMomentStr() {
  let format = Intl.DateTimeFormat("nl-nl", {
    weekday: "short",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return format.format(mealMoment());
}

function getSortedEaters(meal: Meal) {
  return meal.eaters.sort(
    (a: Diner, b: Diner) => zwerverIdToName(a.zwerver)
      .localeCompare(zwerverIdToName(b.zwerver))
  )
}

function getSortedGuests(meal: Meal) {
  return meal.guests.sort(
    (a: Guest, b: Guest) => a.name
      .localeCompare(b.name)
  )
}

function getMealDeadlineStr(meal: Meal) {
  if ((meal.closing_date === null) || (meal.closing_date === meal.date)) {
    let format = Intl.DateTimeFormat("nl-nl", {
      timeStyle: "short",
    });

    return format.format(closingMoment(meal.closing_date, meal.closing_time));
  } else {
    let format = Intl.DateTimeFormat("nl-nl", {
      weekday: "short",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

    return format.format(closingMoment(meal.closing_date, meal.closing_time));
  }
}

function deadlineShouldShow(meal: Meal) {
  if((meal.closing_time === "12:00:00") && ((meal.closing_date === null) || (meal.closing_date === meal.date))) {
    return false;
  }
  return true;
}

</script>
