<template>
  <div
    class="icon-plus icon-btn-inline clickable"
    @click="() => (state.showEditor = true)"
  >
    <guest-modal
      v-if="state.showEditor"
      :guest="{ name: '', diets: '' }"
      :disabled="state.submitting"
      @closeModal="() => (state.showEditor = false)"
      @saveModal="saveGuest"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import { postData } from "../../fetchUtils";

const props = defineProps({
  mealId: { type: Number, required: true },
});

const emit = defineEmits(["guestAdded"]);

const state = reactive({
  showEditor: false,
  submitting: false,
});

function saveGuest({ name, diets }: { name: string; diets: string }) {
  state.submitting = true;
  postData("/api/guest/add/", {
    meal_id: props.mealId,
    guest_name: name,
    guest_diets: diets,
    web: true,
  })
    .then((response) => {
      state.submitting = false;
      state.showEditor = false;
      emit("guestAdded", response);
    })
    .catch((err) => {
      alert(`Het lukt niet om deze gast toe te voegen: ${err}`);
      state.submitting = false;
    });
}
</script>
