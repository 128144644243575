<template>
  <div style="width: 100%">
    <template v-if="state.loading">
      <!-- Intentionally left blank -->
    </template>
    <template v-else-if="state.meals.length === 0">
      Op dit moment zijn geen maaltijden ingepland
    </template>
    <template v-else>
      <randomalignvert smooth>
        <meal
          v-for="meal in state.meals"
          :meal="meal"
          :zwervers="state.zwervers"
          :user="state.user"
          :key="meal.id"
        />
      </randomalignvert>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { getData } from "../fetchUtils";
import type { Meal } from "../models/meal";
import type { User } from "../models/user";
import type { Zwerver } from "../models/zwerver";

const state: {
  loading: Boolean;
  meals: Meal[];
  zwervers: Zwerver[];
  user: User;
} = reactive({
  loading: true,
  meals: [],
  zwervers: [],
  user: undefined,
});

onMounted(() => {
  Promise.all([
    getData<Meal[]>(`/api/meal/view/?web=True`),
    getData<Zwerver[]>(`/api/zwerver/view/short`),
    getData<User>(`/api/user`),
  ])
    .then(([meals, zwervers, user]) => {
      state.meals = meals;
      state.zwervers = zwervers;
      state.user = user;
      state.loading = false;
    })
    .catch((err) => {
      alert(`Maaltijden ophalen lukt niet omdat ${err}`);
    });
});
</script>
