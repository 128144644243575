export function postData(url: string, data: any) {
  return fetch(url, {
    method: "POST",
    mode: "same-origin",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "same-origin",
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      if (response.status < 200 || response.status > 299) {
        throw `Illegal status code ${response.status}: ${response.statusText}`;
      }
      return response.json();
    })
    .catch((err: any) => {
      return mapErrorToMessage(err).then((text) => {
        throw text;
      });
    });
}

export async function getData<T = any>(url: string): Promise<T> {
  return fetch(url, {
    mode: "same-origin",
    cache: "no-cache",
    credentials: "include",
    redirect: "follow",
    referrerPolicy: "same-origin",
  })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      if (response.status < 200 || response.status > 299) {
        throw `Illegal status code ${response.status}: ${response.statusText}`;
      }
      return response.json();
    })
    .catch((err: any) => {
      return mapErrorToMessage(err).then((text) => {
        throw text;
      });
    });
}

async function mapErrorToMessage(err: any) {
  if (err instanceof Response) {
    return await err
      .json()
      .then((json) => {
        if (json.hasOwnProperty("errors")) {
          return json.errors.join(", ");
        }
        let errMsg = getErrorFromField(json);
        if (errMsg != null) {
          return errMsg;
        }
        return err.statusText;
      })
      .catch((e) => {
        return err.statusText;
      });
  }
  if (typeof err === "object" && err !== null) {
    let errMsg = getErrorFromField(err);
    if (errMsg != null) {
      return errMsg;
    }
  }
  if (typeof err === "string") {
    return err;
  }
  return "unkown error";
}

function getErrorFromField(err: any) {
  for (let field of ["detail", "error", "message"]) {
    if (err.hasOwnProperty(field)) {
      return err[field];
    }
  }
  return null;
}
