<template>
  <li :title="'aangemeld door ' + props.buddy">
    {{ props.guest.name }}
    <template v-if="allowEdit">
      <div class="icon-edit icon-btn-inline" @click="editGuest"></div>
      <div class="icon-delete icon-btn-inline" @click="deleteGuest"></div>
    </template>

    <guest-modal
      v-if="state.showEditor"
      :guest="props.guest"
      :disabled="state.submitting"
      @closeModal="() => (state.showEditor = false)"
      @saveModal="updateGuest"
    />
  </li>
</template>

<script setup lang="ts">
import { postData } from "../../fetchUtils";
import { Guest } from "../../models/meal";

import { reactive, PropType } from "vue";

const props = defineProps({
  guest: { type: Object as PropType<Guest>, required: true },
  buddy: { type: String, required: true },
  allowEdit: { type: Boolean, required: true },
});

const emit = defineEmits(["guestChanged", "guestDeleted"]);

const state = reactive({
  showEditor: false,
  submitting: false,
});

function store() {
  state.submitting = true;
}

function editGuest() {
  state.showEditor = true;
}

function updateGuest({ name, diets }: { name: string; diets: string }) {
  state.submitting = true;
  postData("/api/guest/edit/", {
    guest_id: props.guest.id,
    guest_name: name,
    guest_diets: diets,
    web: true,
  })
    .then((response) => {
      state.submitting = false;
      state.showEditor = false;
      emit("guestChanged", response);
    })
    .catch((err) => {
      alert(`Het lukt niet om deze gast te wijzigen: ${err}`);
      state.submitting = false;
    });
}

function deleteGuest() {
  if (!confirm(`Gaat ${props.guest.name} toch niet mee?`)) {
    return;
  }
  postData("/api/guest/remove/", {
    guest_id: props.guest.id,
  })
    .then(() => {
      emit("guestDeleted", props.guest);
    })
    .catch((err) => {
      alert(`Het lukte niet om ${props.guest.name} uit te schrijven. ${err}`);
      location.reload();
    });
}
</script>
