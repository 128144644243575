<script setup lang="ts">
import * as twgl from "twgl.js";
import { m4 } from "twgl.js";
import { flattenDeep, flatten } from "lodash";
import { computed, onMounted, ref } from 'vue';

// Constants to configure when to show this banner
const OWEE_DATES = ['15 Aug', '22 Aug'];
const PRE_OWEE_DURATION_WEEKS = 8;

const now = new Date();
const [oweeStart, oweeEnd] = [
  new Date(`${OWEE_DATES[0]} ${now.getFullYear()}`),
  new Date(`${OWEE_DATES[1]} ${now.getFullYear()} 18:00`),
];
const preOweeStart = new Date(
  oweeStart.getTime() - PRE_OWEE_DURATION_WEEKS * 7 * 24 * 3600 * 1000
);
const isActive = now > preOweeStart && now < oweeEnd;

const shouldShowBanner = computed(() => isActive)

function showBanner(canvas: HTMLCanvasElement) {
  if (!canvas) return;
  const gl = canvas.getContext("webgl");
  const programInfo = twgl.createProgramInfo(gl, [
    "owee-banner-vs",
    "owee-banner-fs",
  ]);

  // Less vertices on mobile (because generally less performant)
  const [sizeX, sizeY] = window.innerWidth < 1000 ? [10, 5] : [20, 10];

  const numQuads = sizeX * sizeY;
  const numTriangles = numQuads * 2;
  const numVerts = numQuads * 4;
  const vertPositions = flattenDeep(
    Array(sizeX)
      .fill(Array(sizeY).fill(null))
      .map((row: null[], i) =>
        row.map((_, j) => {
          const [x1, x2, y1, y2] = [
            i / sizeX,
            (i + 1) / sizeX,
            j / sizeY,
            (j + 1) / sizeY,
          ];
          return [x1, y2, 0, x1, y1, 0, x2, y1, 0, x2, y2, 0]; // todo: more optimised (reused verts)
        })
      ) as number[][][]
  );
  const arrays = {
    position: vertPositions,
    normal: flatten(Array(numVerts).fill([-1, 0, 0])),
    texcoord: vertPositions
      .map((p, i) => (i % 3 == 2 ? null : p))
      .filter((p) => p !== null),
    indices: flatten(
      Array(numQuads)
        .fill(null)
        .map((_, i) => {
          const b = i * 4;
          return [b, b + 1, b + 2, b, b + 2, b + 3];
        })
    ),
  };
  const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays);

  const centerImgSample = document.createElement("img");
  centerImgSample.src = "/static/assets/img/owee_banner_center.png";

  const tex = twgl.createTextures(gl, {
    bg: { src: "/static/assets/img/cloth_texture_cc.jpg" },
    center: { src: "/static/assets/img/owee_banner_center.png" },
    centerCopy: {
      src: `/static/assets/img/owee_banner_center_copy_${
        now > oweeStart && now < oweeEnd ? "during" : "pre"
      }.png`,
    },
  });

  const aspect = canvas.clientWidth / canvas.clientHeight;
  const fov = (30 * Math.PI) / 180;
  const [zNear, zFar] = [0.5, 10];
  const projection = m4.perspective(fov, aspect, zNear, zFar);
  const eye = [-0.5, 0.5, -3];
  const target = [-0.5, 0.5, 0];
  const up = [0, 1, 0];

  const camera = m4.lookAt(eye, target, up);
  const view = m4.inverse(camera);
  const viewProjection = m4.multiply(projection, view);
  const world = m4.rotationY(Math.PI);

  const uniforms = {
    u_lightWorldPos: [2, 3, -1],
    u_lightColor: [1, 1, 1, 1],
    u_ambient: [0, 0, 0, 1],
    u_specular: [1, 1, 1, 1],
    u_shininess: 100,
    u_specularFactor: 1,
    u_diffuseBg: tex.bg,
    u_diffuseCenter: tex.center,
    u_diffuseCenterCopy: tex.centerCopy,
    u_diffuseCenterAr: centerImgSample.width / centerImgSample.height,
    u_resolution: [canvas.width, canvas.height],
    u_viewAr: canvas.width / canvas.height,
    // Animated
    u_viewInverse: camera,
    u_world: world,
    u_worldInverseTranspose: m4.transpose(m4.inverse(world)),
    u_worldViewProjection: m4.multiply(viewProjection, world),
    u_time: 0,
    u_hovering: 0 as 0 | 1,
  };

  canvas.onmouseenter = () => (uniforms.u_hovering = 1);
  canvas.onmouseleave = () => (uniforms.u_hovering = 0);

  function render(time: number) {
    time *= 0.001;

    twgl.resizeCanvasToDisplaySize(canvas);
    gl.viewport(0, 0, canvas.width, canvas.height);

    // We keep these turned off, as they are a large hit to performance
    // gl.enable(gl.DEPTH_TEST);
    // gl.enable(gl.CULL_FACE);
    // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

    uniforms.u_time = time;
    uniforms.u_viewAr = canvas.width / canvas.height;
    uniforms.u_diffuseCenterAr = centerImgSample.width / centerImgSample.height;

    gl.useProgram(programInfo.program);
    twgl.setBuffersAndAttributes(gl, programInfo, bufferInfo);
    twgl.setUniforms(programInfo, uniforms);
    // gl.drawElements(gl.TRIANGLES, bufferInfo.numElements, gl.UNSIGNED_SHORT, 0);
    twgl.drawBufferInfo(
      gl,
      bufferInfo
      // gl.LINE_STRIP
    );

    requestAnimationFrame(render);
  }
  requestAnimationFrame(render);
}

const oweebannercanvas = ref(null);

onMounted(() => {
  const canvas = oweebannercanvas.value;
  // Doing this at the next tick prevents an issue where the canvas size is not yet settled
  setTimeout(() => showBanner(canvas), 0);
});

function click() {
  if (isActive) {
    window.open("https://owee.delftschezwervers.nl", "_blank");
  }
}
</script>
<template>
  <div class="owee-banner" v-if="shouldShowBanner">
    <canvas ref="oweebannercanvas" @click="click"></canvas>
  </div>
</template>
