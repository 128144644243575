<template>
  <modal v-on:close-request="() => emit('closeModal')">
    <template v-slot:header>
      <h3>Gast aanmelden</h3>
    </template>
    <template v-slot:body>
      <table>
        <tbody>
          <tr>
            <td>Naam</td>
            <td>
              <input v-model="state.name" :disabled="disabled" />
            </td>
          </tr>
          <tr>
            <td>Dieet beschrijving</td>
            <td>
              <input v-model="state.diets" :disabled="disabled" />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-slot:footer>
      <button
        @click="submit"
        class="button-modal glow"
        :disabled="props.disabled || state.name === ''"
      >
        Opslaan
      </button>
    </template>
  </modal>
</template>

<script setup lang="ts">
import { reactive, PropType } from "vue";
import { Guest } from "../../models/meal";

const props = defineProps({
  guest: { type: Object as PropType<Guest>, required: true },
  disabled: { type: Boolean, required: true },
});

const emit = defineEmits(["saveModal", "closeModal"]);

const state = reactive({
  name: props.guest.name,
  diets: props.guest.diets,
});

function submit() {
  emit("saveModal", {
    name: state.name,
    diets: state.diets,
  });
}
</script>
