<script setup lang="ts">
import { ref, onMounted } from "vue";
import { create as createRand } from "random-seed";

const props = defineProps({
  smooth: Boolean,
  extraFine: Boolean,
});

const wrapper = ref(null);

const alignmentClassesBlunt = ["align-start", null, "align-end"];
const alignmentClassesFine = [
  "align-start",
  "align-start inbetween",
  null,
  "align-end inbetween",
  "align-end",
];

onMounted(() => {
  const alignmentClasses = props.extraFine
    ? alignmentClassesFine
    : alignmentClassesBlunt;

  let currentIndex: number = alignmentClasses.findIndex((c) => c === null);

  // Same randomness every time within the same day and same pathname
  const rand = createRand(new Date().toDateString() + window.location.pathname);
  wrapper.value.querySelectorAll(":scope > div").forEach((el, i) => {
    if (i === 0) return;

    const indices = alignmentClasses
      .map((_, i) => i)
      .filter((i) => {
        if (!props.smooth) return i !== currentIndex;
        else return Math.abs(currentIndex - i) === 1;
      });

    currentIndex = indices[rand.intBetween(0, indices.length - 1)];
    if (alignmentClasses[currentIndex])
      el.classList.add(...alignmentClasses[currentIndex].split(" "));
  });
  rand.done();
});
</script>
<template>
  <div class="vert-layout-spaced align-random" ref="wrapper">
    <slot />
  </div>
</template>
