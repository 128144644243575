<script setup lang="ts">
import { reactive, ref, onMounted } from "vue";

// This wrapping component expects you to make:
// - one div with the class "card" => will get an expand chevron and an onClick listener
// - one or more divs with the class "expandable" somewhere in it => will be shown when expanded

const wrapper = ref(null);

const props = defineProps({
  animate: {
    type: Boolean,
    default: true,
  },
});

const state = reactive({
  expanded: false,
});

function setMaxHeight(el: HTMLDivElement) {
  el.style.maxHeight = state.expanded
    ? props.animate
      ? `${el.scrollHeight}px`
      : "min-content"
    : "";
}

function toggle() {
  state.expanded = !state.expanded;
  wrapper.value
    .querySelectorAll(".expandable")
    .forEach((el: HTMLDivElement) => setMaxHeight(el));
}

function toggleTap() {
  if (state.expanded) return;
  toggle();
}

function close(e: MouseEvent) {
  if (state.expanded) {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  }
}

onMounted(() => {
  const card = wrapper.value.querySelector(".card");
  if (card) {
    card.addEventListener("click", toggleTap);
    // This line enables you to click on an anchor without closing the card
    card.querySelectorAll("a").forEach((a) => {
      let elementOnClick = a.onclick;
      a.onclick = function (e: MouseEvent) {
        e.stopPropagation();
        if (elementOnClick) {
          return elementOnClick.call(this, a);
        }
      };
    });

    const closeBtn = document.createElement("button");
    closeBtn.className = "close-btn";
    closeBtn.addEventListener("click", close);
    card.appendChild(closeBtn);

    wrapper.value
      .querySelectorAll(".expandable")
      .forEach((el: HTMLDivElement) => {
        new MutationObserver((mutations, _observer) => {
          if (mutations.some((m) => m.type === "childList")) setMaxHeight(el);
        }).observe(el, { childList: true, subtree: true });
      });
  }
});
</script>

<template>
  <div class="expander" :class="{ expanded: state.expanded }" ref="wrapper">
    <slot />
  </div>
</template>
